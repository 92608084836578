import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import './PriceRangeInput.css';
import 'rc-slider/assets/index.css';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const PriceRangeInput = ({ min: initialMin, max: initialMax, onPriceChange }) => {
  const [minPrice, setMinPrice] = useState(initialMin);
  const [maxPrice, setMaxPrice] = useState(initialMax);
  
  useEffect(() => {
    onPriceChange(minPrice, maxPrice);
  }, [minPrice, maxPrice, onPriceChange]);

  const handleSliderChange = (values) => {
    if(values[1] == values[0]){
      if(values[0] == 1000){
        values[0]--;
      }else{
        values[1] ++;
      }
      
    }
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
    
  };

  const sliderStyle = {
    width: '80%',
    margin: '0 auto',
  };

  const handleMinPriceChange = (e) => {

    if(e.target.value < 0){
      e.target.value = 0;
    }

    if(e.target.value === ""){
      setMinPrice('');
    }

    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0 && value <= 1000) {
      setMinPrice(value);
    } else if(value === ""){
      setMinPrice(0);
    }

    console.log(e.target.value);

  };

  const handleMaxPriceChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0 && value <= 1000) {
      setMaxPrice(value);
    }
  };

  return (
    <div className="pricerange">
      <h3 className="label-price">Price Range:</h3>

      <div className="inputs">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="input1">
            <FormControl sx={{ width: '80%' }}>
              <InputLabel
                htmlFor="outlined-adornment-amount"
                sx={{
                  color: 'black',
                  "&.Mui-focused": {
                    color: '#bb0129',
                  },
                }}
              >
                Minimum
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label="Minimum"
                type="number"
                value={minPrice}
                onChange={handleMinPriceChange}
                sx={{
                  height: '2.83rem',
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#bb0129',
                    "& .MuiInputLabel-root": {
                      color: '#bb0129',
                      "&.Mui-focused": {
                        color: '#bb0129',
                      },
                    },
                  },
                }}
                ></OutlinedInput>
            </FormControl>
          </div>



          
          <div className="input1">
            <FormControl sx={{ width: '80%' }}>
              <InputLabel
                htmlFor="outlined-maximum-amount"
                sx={{
                  color: 'black',
                  "&.Mui-focused": {
                    color: '#bb0129',
                  },
                }}
              >
                Maximum
              </InputLabel>
              <OutlinedInput
                id="outlined-maximum-amount"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label="Maximum"
                type="string"
                value={maxPrice >= 1000 ? '1000+' : maxPrice.toString()}
                onChange={handleMaxPriceChange}
                sx={{
                  height: '2.83rem',
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#bb0129',
                    "& .MuiInputLabel-root": {
                      color: '#bb0129',
                      "&.Mui-focused": {
                        color: '#bb0129',
                      },
                    },
                  },
                }}
              ></OutlinedInput>
            </FormControl>
          </div>
        </Box>
      </div>

      <div className="slider-container">
        <Slider
          min={0}
          max={1000}
          step={1}
          range
          defaultValue={[minPrice, maxPrice]}
          onChange={handleSliderChange}
          style={sliderStyle}
          className="price-slider"
        />
      </div>
    </div>
  );
};

export default PriceRangeInput;
