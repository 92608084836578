import React, { useState, useEffect, useRef } from "react";
import "./SearchBar.css";
import Filters from "./Filters/Filters";
import { Grid, Hidden } from '@mui/material';
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Button, DatePicker } from "antd";
import IconButton from "@mui/material/IconButton";
import Config from "../Services/Config";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import { orange } from "@mui/material/colors";
import { IMAGES_URL, API_URL } from "../Services/Config";
import axios from "axios";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GridViewIcon from '@mui/icons-material/GridView';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createFilterOptions } from '@mui/material/Autocomplete';
import MapsComponent from "./MapsComponent";
import GoogleMapsAutocomplete from "./GoogleMapsAutocomplete";
import moment from 'moment';

const { RangePicker } = DatePicker;
const filter = createFilterOptions();
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: '15px',
    "&.Mui-focused fieldset": {
      borderColor: "blue",
    },
  },
  "& .MuiInputLabel-root": {
    color: "grey",
    "&.Mui-focused": {
      color: "blue",
    },
  },
});

function SearchBar({ onSearch }) {

  const [location, setLocation] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [guests, setGuests] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [minRange, setMinRange] = useState(0);
  const [maxRange, setMaxRange] = useState(200);
  const [categories, setCategories] = useState([]);
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const [selectedPropertyType, setSelectedPropertyType] = useState(0);
  const [selectedBedroom, setSelectedBedroom] = useState("");
  const [selectedBeds, setSelectedBeds] = useState("");
  const [selectedBathroom, setSelectedBathroom] = useState("");
  const [long, setLongitude] = useState(0);
  const [lat, setLatitude] = useState(0);
  const [deflt,setDefault] = useState(0);
  const [filtered,setFilter] = useState(0);

  const openMenu = Boolean(anchorElMenu);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const GOOGLE_MAPS_API = "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=$value&location=$latitude,$longitude&types=geocode&key=AIzaSyDSH26MeYe3hxTjWd1QiCn6hsVd1Vvkplw";
  const [selectedCategory, setSelectedCategory] = useState(0);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().startOf('day');
  };


  const scrollContainerRef = useRef(null);

  const handleScroll = (direction) => {
    const scrollAmount = 200; // Adjust as needed
    const container = scrollContainerRef.current;

    if (container) {
      if (direction === 'left') {
        container.scrollLeft -= scrollAmount;
      } else if (direction === 'right') {
        container.scrollLeft += scrollAmount;
      }
    }
  };

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };


  // useState(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       const { latitude, longitude } = position.coords;
  //       // setLongitude(longitude);
  //       // setLatitude(latitude);
  //       // console.log(`Latitude here : ${latitude}, Longitude here : ${longitude}`);
  //     },
  //     (error) => {
  //       console.error(`Error getting geolocation: ${error.message}`);
  //     }
  //   );
  // });

  useState(() => {
    const getCurrentLocationPublicIp = async () => {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      setLongitude(data.longitude);
      setLatitude(data.latitude);
    }

    getCurrentLocationPublicIp();

  },[]);


  const resetAllPropertiesDefault = async () => {
    try {
      

      // setLocation();

      // setFi(allPropertiesDefault);
      setSelectedCategory(0,[
        onSearch({
            location,
            checkIn,
            checkOut,
            guests,
            minPrice,
            maxPrice,
            maxRange,
            minRange,
            selectedCategory,
            selectedPropertyType,
            selectedBedroom,
            selectedBeds,
            selectedBathroom,
            long,
            lat,
          })
      ]);

      const allPropertiesDefault = await Config.getHomeCardDetails(long,lat);
      


      // console.log("category",selectedCategory);

        // onSearch({
        //   location,
        //   checkIn,
        //   checkOut,
        //   guests,
        //   minPrice,
        //   maxPrice,
        //   maxRange,
        //   minRange,
        //   selectedCategory,
        //   selectedPropertyType,
        //   selectedBedroom,
        //   selectedBeds,
        //   selectedBathroom,
        //   long,
        //   lat,
        // });


      // console.log("all Propeties", allPropertiesDefault);
      // console.log("location",location);
      // console.log("check in",checkIn);
      // console.log("checkOut",checkOut);
      // console.log("guests",guests);
      // console.log("minPrice",minPrice);
      // console.log("maxPrice",maxPrice);
      // console.log("minRange",minRange);
      // console.log("maxRange",maxRange);
      // console.log("selectedPropertyType",selectedPropertyType);
      // console.log("selectedBedroom",selectedBedroom);
      // console.log("selectedCategory",selectedCategory);
      // console.log("selectedBeds",selectedBeds);
      // console.log("selectedBathroom",selectedBathroom);
      // console.log("long",long);
      // console.log("lat",lat);

      

    } catch (exception){
      console.error("error : ",exception);
    }
  }

  //on clicking search
  const handleSearchClick = async () => {
    try {
     
      if(long !== 0 && lat !== 0){
        // console.log("location: ",location);
        // console.log("guests: ",guests);
        // console.log("minPrice: ",minPrice);
        // console.log("minPrice: ",minPrice);
        // console.log("maxPrice: ",maxPrice);
        // console.log("selectedCategory: ",selectedCategory);
        // console.log("selectedPropertyType: ",selectedPropertyType);
        // console.log("selectedBedroom: ",selectedBedroom);
        // console.log("selectedBeds: ",selectedBeds);
        // console.log("selectedBathroom: ",selectedBathroom);
        // console.log("long: ",long);
        // console.log("lat: ",lat);
        // console.log("maxRange",maxRange);
  
  
    
        const searchResults = await Config.searchFunctionality(
          location,
          checkIn,
          checkOut,
          guests,
          minPrice,
          maxPrice,
          selectedCategory,
          maxRange,
          selectedPropertyType,
          selectedBedroom,
          selectedBeds,
          selectedBathroom,
          long,
          lat,
        );
  
        console.log("search results",searchResults);
          
  
  
        onSearch({
          location,
          checkIn,
          checkOut,
          guests,
          minPrice,
          maxPrice,
          maxRange,
          minRange,
          selectedCategory,
          selectedPropertyType,
          selectedBedroom,
          selectedBeds,
          selectedBathroom,
          long,
          lat,
        });


      } else {

        console.log("long or lat is 0");

      }


      
    } catch (error) {
      console.error('Error during search:', error);
    }
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}/${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day
      }`;
  };

  const handlePriceChange = ({ min, max }) => {
    setMinPrice(min);
    setMaxPrice(max);
  };
  const handleRangeChange = ({ min, max }) => {
    setMinRange(min);
    setMaxRange(max);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsFilterOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsFilterOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleGuestsChange = (event) => {
    let inputValue = event.target.value;

    if (inputValue === "") {
      setGuests("");
    } else {
      const parsedValue = parseInt(inputValue, 10);
      if (!isNaN(parsedValue) && parsedValue >= 0) {
        setGuests(parsedValue.toString());
      }
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {


    if(category.id !== selectedCategory){
      setSelectedCategory(category.id);
      console.log('Selected Category ID:', category.id);
    } else {
      setSelectedCategory(0);
    }
    
  };

  return (
    <div className="search-bar">
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={10} sm={2} md={1}>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <GoogleMapsAutocomplete location={location} setLocation={setLocation} value={location} />
        </Grid>

        <Grid item xs={12} sm={3} md={2}>
        <RangePicker
          style={{ width: '100%', height: '56px', borderRadius: '15px' }}
          separator=""
          disabledDate={disabledDate}
          onCalendarChange={(dates) => {
            if (dates && dates.length > 0) {
              setCheckIn(dates[0]);
              setCheckOut(dates[1]);
            } else {
              setCheckIn(null);
              setCheckOut(null);
            }
          }}
        />
        </Grid>

        {/* <Grid item xs={12} sm={3} md={2}>
          <CustomTextField
            type="tel"
            label="Guests"
            variant="outlined"
            placeholder="Guest number"
            value={guests}
            onChange={handleGuestsChange}
            style={{ display: Hidden, appearance: 'none', WebkitAppearance: 'none' , MozAppearance: 'textfield'}}
            fullWidth
          />
        </Grid> */}

        <Grid item xs={12} sm={3} md={2} container justifyContent={isSmallScreen ? 'center' : 'start'} style={{ alignItems: 'center', display: 'flex' }}>

          {/* <Button onClick={resetAllPropertiesDefault} style={{
            backgroundColor: 'lightgrey',
            color: 'black',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            border: 'none',
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center', // Align items vertically
            justifyContent: 'center',
            
            }}>
            Default
          </Button> */}


          <IconButton aria-label="filter" onClick={handleClick}>
            <TuneIcon fontSize="inherit" style={{ color: "black" }} />
          </IconButton>
          
          
          
          <IconButton aria-label="search"
            id="basic-button"
            aria-controls={openMenu ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClickMenu}>
            <GridViewIcon fontSize="inherit" style={{ color: "black" }} />
          </IconButton>



          <IconButton aria-label="search" onClick={handleSearchClick}>
            <SearchIcon fontSize="inherit" style={{ color: "black" }} />
          </IconButton>


          


        </Grid>


      </Grid>


{/* 
      <Grid container item xs={10} sm={11} md={12} spacing={2} alignItems="center" justifyContent="center" style={{ margin: '20px', overflowX: 'auto', overflowY: 'auto', whiteSpace: 'nowrap', width: '100%', position: 'relative' }}>
  
      <div style={{ position: 'relative', overflowX: 'auto', overflowY: 'auto', whiteSpace: 'nowrap', width: '100%' }}> */}
  
  {/* Left Button */}
  {/* <div style={{ position: 'absolute', left: '0%', transform: 'translateY(-50%)', zIndex: '2', top: '70%', borderRadius: '20px' }}>
    <button
      style={{
        transform: 'translateY(-50%)', zIndex: '2',
        padding: '10px',
        backgroundColor: '#d7d7d7',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        height: '50px',
        width: '50px',
        borderRadius: '20px',
      }}
      onClick={() => handleScroll('left')}
    >
      <div style={{ borderRadius: '30px', overflow: 'hidden' }}>
        <img
          src="https://img.icons8.com/material-rounded/452/chevron-left.png"
          alt="IconLeft"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
    </button>
  </div> */}

  {/* Category Menu */}
  {/* <div style={{ display: 'flex', overflowX: 'hidden', position: 'relative' ,marginLeft : '30px', marginRight: '30px'}} ref={scrollContainerRef}>
    {categories.map((category) => (
      <MenuItem
        key={category.id}
        onClick={() => handleCategoryClick(category)}
        selected={selectedCategory === category.id}
        sx={{ backgroundColor: selectedCategory === category.id ? '#efefef !important' : 'inherit',
        '&:hover': {
          backgroundColor: 'inherit', // Change to the desired hover background color
        },
      }}
        style={{ padding: '5px' }}
      >
        <div className={`category ${selectedCategory === category.id ? 'selected' : ''}`}>
          <img src={`${IMAGES_URL}${category.icon}`} alt={category.name} style={{opacity:'0.6'}}/>
          <div className="category-name">{category.name}</div>
        </div>
      </MenuItem>
    ))}
  </div> */}

  {/* Right Button */}
  {/* <div style={{position: 'absolute', right: '0%', transform: 'translateY(-50%)', zIndex: '2', top: '50%', borderRadius: '20px' }}>
    <button
      style={{
        position: 'fixed', right: '5%', transform: 'translateY(-50%)', zIndex: '2',
        padding: '10px',
        backgroundColor: '#d7d7d7',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        height: '50px',
        width: '50px',
        marginRight: '10px',
        borderRadius: '20px',
      }}
      onClick={() => handleScroll('right')}
    >
      <div style={{ overflow: 'hidden' }}>
        <img
          src="https://img.icons8.com/material-rounded/452/chevron-right.png"
          alt="IconRight"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
    </button>
  </div>

  </div>
</Grid> */}



      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          id={id}
          open={open}
          onClose={handleClose}
          style={{
            width: '300px',
            height: '400px',
            padding: '16px',
          }}
        >
          <Filters
            isOpen={isFilterOpen}
            onApplyFilters={handlePriceChange}
            onPriceChange={handlePriceChange}
            onRangeChange={handleRangeChange}
            selectedPropertyType={selectedPropertyType}
            setSelectedPropertyType={setSelectedPropertyType}
            selectedBedroom={selectedBedroom}
            setSelectedBedroom={setSelectedBedroom}
            selectedBeds={selectedBeds}
            setSelectedBeds={setSelectedBeds}
            selectedBathroom={selectedBathroom}
            setSelectedBathroom={setSelectedBathroom}
          />
        </div>
      </Popover>

      <Menu
        id="basic-menu"
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {categories.map((category) => (
          <MenuItem
            key={category.id}
            onClick={() => handleCategoryClick(category)}
            selected={selectedCategory === category.id}
            sx={{ backgroundColor: selectedCategory === category.id ? '#efefef !important' : 'inherit' }}
          >
            <div className={`category ${selectedCategory === category.id ? 'selected' : ''}`}>
              <img src={`${IMAGES_URL}${category.icon}`} alt={category.name} />
              <div className="category-name">{category.name}</div>
            </div>
          </MenuItem>
        ))}
      </Menu>

    </div>

  );
}

export default SearchBar;