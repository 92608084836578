import React, { useState, useEffect } from "react";
import Navbar from "../../../NavBar/NavBar";
import Config from "../../../Services/Config";
import "./Billing.css";
import { Tabs, Tab, Typography, Box, Grid, Paper, Button, Modal } from '@mui/material';
import SnackbarComponent from "../../../SnackBar/SnackBar";
import TransactionHistory from "./TransactionHistory/TransactionHistory"
import PaymentMethodPopup from "./PaymentMethodPopup";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px', // Rounded edges
  transition: 'transform 0.3s ease-out', // Animation for the transform property
};

function PaymentMethodsComponent() {
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [transactionHistory, setTransactionHistory] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [addPaymentOpen, setPaymentOpen] = useState(false);

  const handleSetOpenDialog = () => {
    setOpenDialog(true);
  }
  const handleSetCloseDialog = () => {
    setOpenDialog(false);
  }


  const handleAddPaymentOpen = () => {
    setPaymentOpen(true);
  };

  const handleAddPaymentClose = () => {
    setPaymentOpen(false);
  };

  // Simulate fetching payment methods (replace with actual fetch call)
  useEffect(() => {
    const fetchCardsData = async () => {
      try {
        const data = await Config.getPaymentMethods();
        console.log('Payment Methods:', data); // Log the data received from the API
        setPaymentMethods(data.data);
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    };

    const fetchTransactionData = async () => {
      try {
        const data = await Config.getTransactionHistory();
        console.log(data.data);
        setTransactionHistory(data.data);
      } catch (error) {
        console.error('Error fetching transaction history:', error);
      }
    };

    fetchTransactionData();
    fetchCardsData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  async function handleRemoveClickPopup(cardId) {
    handleOpen()
    setSelectedCardId(cardId)
  }

  async function handleRemoveClickClose(cardId) {
    handleClose()
    setSelectedCardId(cardId)
  }

  async function handleRemoveClick() {
    try {
      const result = await Config.removeCard(selectedCardId);
      if (result.success) {
        setOpenSnackbar(true);
        setSnackbarMessage(result.message);
        setSnackbarSeverity("success");
        window.location.reload()
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(result.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }


  function getCardBrandLogo(brand) {
    // const brandLower = brand.toLowerCase();
    // console.log(brand)
    const basePath = '/assets/logos'; // Adjust based on where you store your images
    const logoPaths = {
      visa: `/visa.svg`,
      mastercard: `/mastercard.svg`,
      amex: `/american-express.svg`,
      diners: `/diners-club.svg`,
      discover: `/discover.svg`,
      jcb: `/jcb.svg`,
      unionpay: `/unionpay.png`,


      // Add other brands as needed
    };
    return logoPaths[brand] || `${basePath}/default.png`; // Default logo if brand not found
  }

  return (
    <div>
      <Navbar openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} />
      <div className="myContainer">
        <div className="myContainer-item">
          <Box sx={{ borderBottom: 1, borderColor: 'divider',display:'flex',justifyContent: 'space-between' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="Payment Methods" />
              <Tab label="Transaction History" />
            </Tabs>

            <Button variant="contained" color="primary" onClick={handleAddPaymentOpen}>
              Add Payment
            </Button>

          </Box>
          <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2}>
          {paymentMethods && paymentMethods.map((method, index) => (
            <Grid item sx={{ margin: 2, marginBottom: 0 }} key={index} xs={12} sm={12} md={12}>
              <Paper elevation={3} style={{ padding: "20px", display: 'flex', alignItems: 'center' }}>
                <img
                  className="card-image"
                  src={getCardBrandLogo(method.card.brand)}
                  alt={method.card.brand}
                  style={{ height: '80px', width: '80px', marginRight: '4%', objectFit: 'contain' }}
                />
                <div>
                  <Typography className="card-title" variant="h6">
                    Ending in ****{method.card.last4}
                  </Typography>
                  <Typography className="card-exp" variant="body1" style={{ fontWeight: "500" }} color="text.secondary">
                    Exp: {method.card.exp_month}/{method.card.exp_year}
                  </Typography>
                </div>
                <Button style={{ marginLeft: 'auto', color: "#bb0129" }} onClick={() => handleRemoveClickPopup(method.id)} color="error">
                  Remove
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <TransactionHistory data={transactionHistory} />
          </TabPanel>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirmation
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to remove this card?
            </Typography>
            <div style={{ marginLeft: 'auto', marginTop: '20px' }}>
              <Button variant="text" sx={{ color: 'grey', marginRight: '5px' }} onClick={handleRemoveClickClose}>Cancel</Button>
              <Button variant="text" color="primary" onClick={handleRemoveClick}>Yes, Remove</Button>
            </div>
          </div>
        </Box>
      </Modal>


      

      <SnackbarComponent
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <PaymentMethodPopup isOpen={addPaymentOpen} onClose={handleAddPaymentClose}/>


    </div>

    


  );


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
}

export default PaymentMethodsComponent;
