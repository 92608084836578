// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import AuthService from './Services/AuthService';
import Config from './Services/Config';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userData = await Config.getUserDetails();
        // console.log("user data : ",userData);
        setUserDetails(userData.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    const token = AuthService.getToken();
    if (token) {
      setIsAuthenticated(true);
      fetchUserDetails(); // Fetch user details if token is available
    }
  }, []);

  const login = async (body) => {
    const response = await AuthService.login(body);
    if (response.success) {
      setIsAuthenticated(true);
    }
    return response;
  };

  const logout = async () => {
    const response = await AuthService.logout();
    setIsAuthenticated(false);
    return response;
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userDetails, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
