import React, { useState } from 'react';
import './RatingSelector.css'; 

const RatingSelector = ({ onRate }) => {
  const [selectedRating, setSelectedRating] = useState(1);

  const handleStarClick = (rating) => {
    setSelectedRating(rating);
    onRate(rating); // Pass the selected rating to the parent component if needed
  };

  return (
    <div className='star'>
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          style={{
            fontSize: "1rem",
            cursor: "pointer",
          }}
          onClick={() => handleStarClick(star)}
        >
          {star <= selectedRating ? (
    <span role="img" aria-label="filled star " style={{ fontSize: '20px' }}>&#11088;</span>
) : (
    <span role="img" aria-label="unfilled star" style={{ fontSize: '24px' }}>&#10032;</span>
)}

        </span>
      ))}
    </div>
  );
};

export default RatingSelector;
