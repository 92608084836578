import React, { useState, useEffect  } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import './GoogleMapsAutocomplete.css';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: '15px',
    "&.Mui-focused fieldset": {
      borderColor: "blue",
    },
  },
  "& .MuiInputLabel-root": {
    color: "grey",
    "&.Mui-focused": {
      color: "blue",
    },
  },
});

const GoogleMapsAutocomplete = ({ setLocation }) => {
  const [address, setAddress] = useState('');


  const handleSelect = async (selectedAddress) => {
    try {

      // console.log("address",selectedAddress);

      if (selectedAddress.trim() !== '') {
        const results = await geocodeByAddress(selectedAddress);
        const placeId = results[0].place_id;

        // console.log(selectedAddress);

        setAddress(selectedAddress);
        setLocation(selectedAddress + "!_!_!_!_" + placeId);
      } else {
        setAddress('');
        setLocation("");
      }
    } catch (error) {
      console.error('Error selecting address: ', error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      handleSelect(address);
    }
   
  };

  const handleBlur = () => {
    if (address.trim() === '') {
      setAddress('');
      setLocation('');
    }else{
      handleSelect(address);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
     
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <CustomTextField
              value={address}
              {...getInputProps({
                placeholder: 'Location',
                label: "Where",
                className: 'location-search-input',
                fullWidth: true,
                onBlur: handleBlur,
                onKeyDown: handleKeyDown,
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  {...getSuggestionItemProps(suggestion, {
                    className: suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item',
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              ))}

            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default GoogleMapsAutocomplete;
