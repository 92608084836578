import React, { useState } from 'react';
import './PaymentMethod.css'; // Import your stylesheet
import { Modal, Box } from '@mui/material';
import SnackbarComponent from "../../../SnackBar/SnackBar";
import Config from '../../../Services/Config';

const PaymentMethod = ({ closeAddPayment, openLoginForm }) => {

  // console.log("sign up")

  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [CvcTo, setCvcTo] = useState('');
  const [cardType, setCardType] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // const handleSignup = (e) => {
  //   e.preventDefault();
  //   // Implement your signup logic here
  //   console.log('Signup button clicked!');
  //   console.log('Username:', username);
  //   console.log('Email:', email);
  //   console.log('Password:', password);

  const hanldeNumberCard = (event) => {
    let inputValue = event.target.value;

    if (inputValue === "") {
      setCardNumber("");
    } else {
      // const parsedValue = parseInt(inputValue, 10);
      if (!isNaN(parseInt(inputValue)) && inputValue.length > 0) {
        setCardNumber(inputValue);
      }
    }
  };

  useState(() => {
    setCardName('');
    setCardNumber('');
    setExpiryDate('');
    setCvcTo('');
    setCardType('');
  });



  //   // Add your signup API call or other logic here
  // };


  const handleAddPayment = async (e) => {
    e.preventDefault();
    try {
      const body = {
        name : cardName,
        number : cardNumber,
        expiry: expiryDate,
        cvc: CvcTo,
        type: cardType,
      };

      const result = await Config.addCard(body);
      if(result.status){
         
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(result.error);
        setSnackbarSeverity("error");
      }

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="signup-form">
      {/* <h2 className="login-signup-txt">Sign Up</h2> */}

      <div className="container">
      <div className="textSignUp"><label>Payment Method</label></div>
      <div className="close-btn">
        <button className="close-button" onClick={closeAddPayment}>
          x
        </button>
      </div>
    </div>

      <form onSubmit={handleAddPayment} className="form-container" >

        <div className="form-group">
          <label htmlFor="cardName" className="signup-label">
            Name on Card *
          </label>
          <input
            type="text"
            id="cardName"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
            className="signup-input"
            placeholder="Max length: 20 char"
            required
            maxLength={20}
          />
        </div>

        <div className="form-group">
          <label htmlFor="cardNumber" className="signup-label">
            Card Number *
          </label>
          <input
            type="tel"
            id="cardNumber"
            required
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            className="signup-input"
            placeholder="Max length : 20 digit"
            maxLength={20}
          />
        </div>


        <div className="form-group">
          <label htmlFor="expiry" className="signup-label">
            Expiry Date *
          </label>
          <input
            type="text"
            id="expiry"
            required
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            className="signup-input"
            placeholder="Max length : 30 char"
            maxLength={30}
          />
        </div>

        <div className="form-group">
          <label htmlFor="cvcTo" className="signup-label">
            Cvc to *
          </label>
          <input
            type="tel"
            id="cvcTo"
            required
            style={{ appearance: 'none', WebkitAppearance: 'none' , MozAppearance: 'textfield'}}
            value={CvcTo}
            // onChange={(e) => setPhoneNumber(e.target.value)}
            onChange = {(e) => setCvcTo(e.target.value)}
            className="signup-input"
            maxLength={4}
            placeholder="Max length : 4 number"
            
          />
        </div>

        <button type="submit" className="signup-btn">
          Add
        </button>

        <SnackbarComponent
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />

      </form>


      
    </div>
  );
};

export default PaymentMethod;
