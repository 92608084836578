import React, { useState } from "react";
import "./ResetPassword.css";
import axios, { AxiosHeaders } from "axios";
import Button from "@mui/material/Button";
import AuthService from "../Services/AuthService";
import SnackbarComponent from "../SnackBar/SnackBar";
import { Anchor } from "antd";

const ResetPassword = ({ closeResetPasswordAndOverlay , closeResetPasswordPage }) => {
  const [email, setEmail] = useState("");


  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  var ResetPasswordResponseMessage;

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const body = {
        email: email,
      };
    AuthService.resetPassword(body).then(
      (response) => {
        let loginResponseMessage, severity;

        if (response.success) {
          //loginResponseMessage = "Password Reset Was Successful";
          loginResponseMessage = response.message;
          severity = "success";
          setTimeout(() => {
            window.location.reload(false);
        }, 500);
        } else {
          loginResponseMessage = response.message;
          severity = "error";
        }

        setOpenSnackbar(true);
        setSnackbarMessage(loginResponseMessage);
        setSnackbarSeverity(severity);

        console.log(loginResponseMessage);
      },
      (error) => {
        console.log("Password Reset failed", error);
      }
    );
  } catch (error) {
    console.error(error);
  }
  };

  return (
    <div className="reset-password-container">
      {/* <h2 className="reset-password-signup-txt">Welcome Back</h2> */}

      <div className="container">
      <div className="text">Reset Password</div>
      <div className="close-btn">
        <button className="close-button" onClick={closeResetPasswordPage}>
          x
        </button>
      </div>
    </div>

      <form  onSubmit={handleResetPassword}>
        
        <div className="form-container">
        <div className="form-group">
          <label htmlFor="email" className="reset-password-label">
            Email *
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="reset-password-input"
            placeholder="E-mail"
          />
        </div>

        <Button type="submit" variant="container" className="reset-password-btn">
          Send Email
        </Button>

        <SnackbarComponent
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
          <br/>



        </div>

        
      </form>
      {/* <button onClick={logout}>Logout</button> */}
    </div>
  );
};

export default ResetPassword;
