import React from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ResetPassword from "../LoginSignup/ResetPassword";

const LoginPopup = ({ isOpen, onClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "10px",
  };






  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ResetPassword
          closeResetPasswordAndOverlay={onClose}
          closeResetPasswordPage={onClose}
        />
      </Box>
    </Modal>
  );
};

export default LoginPopup;
