import React from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Signup from "../LoginSignup/Signup";

const SignupPopup = ({ isOpen, onClose, onSwitchToLogin }) => {
  const boxStyle = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // width: "30%",
    // bgcolor: "white",
    // border: "2px solid #000",
    // boxShadow: 24,
    // borderRadius: "20px",


    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "10px",
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="signup-modal-title"
      aria-describedby="signup-modal-description"
    >
      <Box sx={boxStyle}>
        <Signup
          closeSignupAndOverlay={onClose}
          openLoginForm={onSwitchToLogin}
        />
      </Box>
    </Modal>

  );
};

export default SignupPopup;
