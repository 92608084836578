import React, { useState, useEffect } from "react";
import Config from "../../Services/Config";
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import './Owner.css';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';
import { IMAGES_URL } from "../../Services/Config";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import EditMapComponent from "../../SearchBar/EditMaps";
const googleMapsApiKey = 'AIzaSyDSH26MeYe3hxTjWd1QiCn6hsVd1Vvkplw';


const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#bb0129", // Color for the border when focused
    },
  },
  "& .MuiInputLabel-root": {
    // Style for the label
    color: "grey", // Default label color
    "&.Mui-focused": {
      color: "#bb0129", // Color for the label when focused
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const EditProperty = ({
  propertyDetails,
  handleCloseEditDialog,
  handleNewNameChange,
  handleNewPriceChange,
  handleNewShortDescChange,
  handleNewDescriptionChange,
  handleNewCategoryChange,
  handleNewTypeChange,
  handleNewMinDurationChange,
  handleNewLocationChange,
  handleNewLongitudeChange,
  handleNewLatitudeChange,
  handleNewInstantChange,
  handleNewOccupancyChange,
  handleNewNumBedroomChange,
  handleNewNumBedsChange,
  handleNewNumGuestsChange,
  handleNewNumDedicatedBathroomsChange,
  handleNewNumSharedBathroomsChange,
  handleNewNumPrivateBathroomsChange,
  handleNewStreetChange,
  handleNewCityChange,
  handleNewStateChange,
  handleNewCountryChange,
  handleNewZipCodeChange,
  handleNewAmenitiesChange,
  selectedAmenities,
  setSelectedAmenities,
  setNewImages,
  x,
}) => {

  const [properties, setProperties] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [propertyData, setPropertyData] = useState({
    action: '',
    name: '',
    price: '',
    short_description: '',
    description: '',
    category_id: '',
    type_id: '',
    min_duration: '',
    location: '',
    longitude: '',
    latitude: '',
    occupancy_status_id: 0,
    instant_booking: 0,
    amenities: selectedAmenities,
    num_bedrooms: '',
    num_beds: '',
    num_guests: '',
    dedicated_bathroom: '',
    private_bathroom: '',
    shared_bathroom: '',
    street: '',
    city: '',
    state: '',
    country: '',
    zip_code: ''
  });

  useEffect(() => {
    if (propertyDetails) {
      setPropertyData({
        action: propertyDetails.action || "",
        name: propertyDetails.name || "",
        price: propertyDetails.price || "",
        short_description: propertyDetails.short_description || "",
        description: propertyDetails.description || "",
        category_id: propertyDetails.category_id || "",
        type_id: propertyDetails.type_id || "",
        min_duration: propertyDetails.min_duration || "",
        location: propertyDetails.location || "",
        longitude: propertyDetails.longitude || "",
        latitude: propertyDetails.latitude || "",
        instant_booking: propertyDetails.instant_booking === 1 ? 1 : 0,
        occupancy_status_id: propertyDetails.occupancy_status_id === 1 ? 1 : 0,
        images: propertyDetails.property_images || [],
        amenities: propertyDetails.amenities || [],
        num_bedrooms: propertyDetails.num_bedrooms || "",
        num_beds: propertyDetails.num_beds || "",
        num_guests: propertyDetails.num_guests || "",
        dedicated_bathroom: propertyDetails.dedicated_bathroom || "",
        shared_bathroom: propertyDetails.shared_bathroom || "",
        private_bathroom: propertyDetails.private_bathroom || "",
        street: propertyDetails.street || "",
        city: propertyDetails.city || "",
        state: propertyDetails.state || "",
        country: propertyDetails.country || "",
        zip_code: propertyDetails.zip_code || "",
      });
      setImagePreviews(propertyDetails.property_images.map(image => IMAGES_URL + image.image_url));
      setSelectedAmenities(propertyDetails.amenities || []);
      setNewAmenities(propertyDetails.amenities || []);
      setNewInstant(propertyDetails.instant_booking === 1 ? 1 : 0);
      setNewOccupancy(propertyDetails.occupancy_status_id === 1 ? 1 : 0);
    }
  }, [propertyDetails]);
  const [newName, setNewName] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [newShortDesc, setNewShortDesc] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [newType, setNewType] = useState('');
  const [newMinDuration, setNewMinDuration] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [newLongitude, setNewLongitude] = useState('');
  const [newLatitude, setNewLatitude] = useState('');
  const [newInstant, setNewInstant] = useState('');
  const [newOccupancyStatus, setNewOccupancy] = useState('');
  const [newNumBedroom, setNewNumBedroom] = useState('');
  const [newNumBeds, setNewNumBeds] = useState('');
  const [newNumGuests, setNewNumGuests] = useState('');
  const [newNumDedicatedBathrooms, setNewNumDedicatedBathrooms] = useState('');
  const [newNumSharedBathrooms, setNewNumSharedBathrooms] = useState('');
  const [newNumPrivateBathrooms, setNewNumPrivateBathrooms] = useState('');
  const [newStreet, setNewStreet] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [newZipCode, setNewZipCode] = useState('');
  const [newAmenities, setNewAmenities] = useState([]);
  const [loading, setLoading] = useState(true);


  
  const handleLocationChangee = ({ latitude, longitude }) => {
    setNewLatitude(latitude);
    handleNewLatitudeChange(latitude);

    setNewLongitude(longitude);
    handleNewLongitudeChange(longitude);
    // console.log("lat",latitude,"long",longitude);
    ///setPropertyData({ ...propertyData,latitude:latitude,longitude: longitude });
  };

  const getAmenityChangeHandler = (amenity) => (e) => {
    const isChecked = e.target.checked;

    setSelectedAmenities((prevSelectedAmenities) => {
      const isAlreadySelected = prevSelectedAmenities.some((a) => a.id === amenity.id);

      if (isChecked && !isAlreadySelected) {
        return [...prevSelectedAmenities, amenity];
      }
      if (!isChecked && isAlreadySelected) {
        return prevSelectedAmenities.filter((selectedAmenity) => selectedAmenity.id !== amenity.id);
      }
      return prevSelectedAmenities;
    });
    handleNewAmenitiesChange(amenity, isChecked);
  };

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const result = await Config.getOwnerProperties();
        
        setProperties(result.data);
        console.log(result.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false); // Set loading to false whether the fetch is successful or not
      }
    };

    fetchProperties();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await Config.getCategoriesDetails();

      if (Array.isArray(data)) {
        setCategories(data);
      } else {
        console.error('Invalid data structure for categories:', data);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);


  const fetchTypes = async () => {
    try {
      const data = await Config.getTypesDetails();

      if (Array.isArray(data)) {
        setTypes(data);
      } else {
        console.error('Invalid data structure for types:', data);
      }
    } catch (error) {
      console.error('Error fetching types:', error);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, []);



  const handleFileChange = async (event) => {
    const files = event.target.files;//yali nzedo

    const previews = [...imagePreviews];

    const allImages = [];

    const promiseImages = [];

    // console.log("previews",previews);

    if(previews.length > 0){
      for(let i = 0 ; i < previews.length ; i++){
        allImages.push(previews[i]);
      }
    }
    
    
    // console.log("files",files.length);
    if(files.length > 0){
      for(let i = 0 ; i < files.length ; i++){
        let formData = new FormData();
        formData.append(`image[${i}]`,files[i]);
        promiseImages.push(Config.uploadImgsEdit(formData));
      }
    }
    

    console.log("promise images",promiseImages);

      const uploadResults = await Promise.all(promiseImages);
      const imageLocations = uploadResults.map((data) => data.location);

      console.log("images location : ", imageLocations);


         if (imageLocations.length > 0) {
          for (let i = 0; i < imageLocations.length; i++) {
            const reader = new FileReader();
            reader.onload = (e) => {
              previews.push(e.target.result);
              // allImages.push(e.target.result);
              if (previews.length === imagePreviews.length + imageLocations.length) {
                // if (allImages.length === imagePreviews.length + imageLocations.length) {
                setImagePreviews(previews);
                // setImagePreviews(allImages);
              }

            };
            reader.readAsDataURL(files[i]);
          }
        }

        setNewImages([...allImages, ...imageLocations]);
        // setNewImages([...]);
        console.log("All Images:", [...allImages, ...imageLocations]);
        // console.log("previews",previews);
      }

  

  const clearForm = () => {
    setImagePreviews([]);
    setNewImages([]);
    document.getElementById('file-upload').value = '';
  };

  const handleDeleteImage = (indexToDelete) => {
    setImagePreviews((prevPreviews) => prevPreviews.filter((_, index) => index !== indexToDelete));
  
    // Filter out the deleted image from the combined array of existing and new images
    setNewImages((prevImages) => {
      const combinedImages = [
        ...propertyDetails?.property_images.map(image => ({
          name: image.image_url.split('/').pop(),
          url: image.image_url,
        })) || [],
        ...prevImages
      ];
      
      return combinedImages.filter((_, index) => index !== indexToDelete);
    });
  };
  

  const fetchAmenities = async () => {
    try {
      const data = await Config.getAmenitiesDetails();

      if (Array.isArray(data)) {
        setAmenities(data);
      } else {
        console.error('Invalid data structure for amenities:', data);
      }
    } catch (error) {
      console.error('Error fetching amenities:', error);
    }
  };

  useEffect(() => {
    fetchAmenities();
  }, []);

  useEffect(() => {
  }, [selectedAmenities]);

  const blue = {
    100: '#daecff',
    200: '#b6daff',
    300: '#66b2ff',
    400: '#3399ff',
    500: '#007fff',
    600: '#0072e5',
    700: '#0059B2',
    800: '#004c99',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const StyledInputRoot = styled('div')(({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  `);

  const StyledInput = styled('input')(({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
    };
    border-radius: 8px;
    margin: 0 8px;
    padding: 10px 12px;
    outline: 0;
    min-width: 0;
    width: 3rem;
    height: 1rem;
    text-align: center;
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  `);

  const StyledButton = styled('button')(({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    line-height: 1.5;
    border: 1px solid;
    border-radius: 999px;
    border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    width: 26px;
    height: 26px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      cursor: pointer;
      background: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[500] : blue[400]};
      color: ${grey[50]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  
    &.increment {
      order: 1;
    }
  `);

  const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    const { value, onChange } = props;

    const handleIncrement = () => {
      console.log('Increment button clicked');
      const incrementedValue = (typeof value === 'number' ? value : parseFloat(value)) + 1;
      onChange(incrementedValue);
    };

    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <AddIcon fontSize="small" />,
            className: 'increment',
            onClick: handleIncrement,
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small" />,
          },
        }}
        {...props}
        ref={ref}
      />
    );
  });


  return (
    <div>
      {loading ? (
        // Show the backdrop while loading
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (

        <div className="owner-container">
          <div className="profile-item-container">

            <IconButton
              aria-label="close"
              onClick={handleCloseEditDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>

              <h2>General Information</h2>

              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <CustomTextField
                    type="text"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    placeholder={propertyData.name}
                    maxLength={50}
                    value={newName !== '' ? newName : propertyData.name}
                    onChange={(e) => {
                      const newNameValue = e.target.value.slice(0, 50);
                      propertyData.name = e.target.value.slice(0, 50);
                      console.log('New Name:', newNameValue);
                      setNewName(newNameValue);
                      handleNewNameChange(newNameValue);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <CustomTextField
                    type="number"
                    label="Price"
                    variant="outlined"
                    maxLength={4}
                    placeholder={String(propertyData.price)}
                    fullWidth
                    value={newPrice !== '' ? newPrice : propertyData.price}
                    onChange={(e) => {
                      const newPriceValue = e.target.value.slice(0, 10);;
                      propertyData.price = e.target.value.slice(0, 10);;
                      setNewPrice(newPriceValue);
                      handleNewPriceChange(newPriceValue);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    style={{ width: '100%', marginBottom: "3%" }}
                    type="text"
                    label="Short Description"
                    placeholder={propertyData.short_description}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={4}
                    maxLength={200}
                    value={newShortDesc !== '' ? newShortDesc : propertyData.short_description}
                    onChange={(e) => {
                      const newShortDescValue = e.target.value.slice(0, 200);
                      propertyData.short_description = e.target.value.slice(0, 200);
                      setNewShortDesc(newShortDescValue);
                      handleNewShortDescChange(newShortDescValue);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    style={{ width: '100%', marginBottom: "3%" }}
                    type="text"
                    label="Description"
                    placeholder={propertyData.description}
                    margin="normal"
                    variant="outlined"
                    maxLength={200}
                    multiline
                    rows={4}
                    value={newDescription !== '' ? newDescription : propertyData.description}
                    onChange={(e) => {
                      const newDescriptionValue = e.target.value.slice(0, 200);
                      propertyData.description = e.target.value.slice(0, 200);
                      setNewDescription(newDescriptionValue);
                      handleNewDescriptionChange(newDescriptionValue);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    id="outlined-select-category"
                    select
                    label="Select Category"
                    defaultValue="Select a category"
                    helperText="Please select a category"
                    style={{ width: "100%" }}
                    value={newCategory !== null && newCategory !== '' ? newCategory : propertyData.category_id}
                    onChange={(e) => {
                      const newCategoryValue = e.target.value;
                      setNewCategory(newCategoryValue);
                      handleNewCategoryChange(newCategoryValue);
                    }}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    id="outlined-select-type"
                    select
                    label="Select type"
                    defaultValue="Select a type"
                    helperText="Please select a type"
                    style={{ width: "100%" }}
                    value={newType !== null && newType !== '' ? newType : propertyData.type_id}
                    onChange={(e) => {
                      const newTypeValue = e.target.value;
                      setNewType(newTypeValue);
                      handleNewTypeChange(newTypeValue);
                    }}
                  >
                    {types.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Minimum Duration"
                    placeholder={String(propertyData.min_duration)}
                    type="number"
                    multiline
                    maxRows={4}
                    value={newMinDuration !== '' ? newMinDuration : propertyData.min_duration}
                    onChange={(e) => {
                      const newMinDurationValue = e.target.value.slice(0, 3);
                      propertyData.min_duration = e.target.value.slice(0, 3);
                      setNewMinDuration(newMinDurationValue);
                      handleNewMinDurationChange(newMinDurationValue);
                    }}
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    id="outlined-multiline-flexible1"
                    label="Location"
                    placeholder={propertyData.location}
                    type="text"
                    multiline
                    maxRows={4}
                    value={newLocation !== '' ? newLocation : propertyData.location}
                    onChange={(e) => {
                      const newLocationValue = e.target.value.slice(0, 50);
                      propertyData.location = e.target.value.slice(0, 50);
                      setNewLocation(newLocationValue);
                      handleNewLocationChange(newLocationValue);
                    }}
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    id="outlined-multiline-flexible2"
                    label="Longitude"
                    placeholder={String(propertyData.longitude)}
                    type="text"
                    multiline
                    maxRows={4}
                    value={newLongitude !== '' ? newLongitude : propertyData.longitude}
                    onChange={(e) => {
                      const newLongitudeValue = e.target.value;
                      propertyData.longitude = e.target.value;
                      setNewLongitude(newLongitudeValue);
                      handleNewLongitudeChange(newLongitudeValue);
                    }}
                    style={{ display:'none', width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    id="outlined-multiline-flexible3"
                    label="Latitude"
                    placeholder={String(propertyData.latitude)}
                    type="text"
                    multiline
                    maxRows={4}
                    value={newLatitude !== '' ? newLatitude : propertyData.latitude}
                    onChange={(e) => {
                      const newLatitudeValue = e.target.value;
                      propertyData.latitude = e.target.value;
                      setNewLatitude(newLatitudeValue);
                      handleNewLatitudeChange(newLatitudeValue);
                    }}
                    style={{ display:'none', width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <FormGroup style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <FormControlLabel
                      label="Monthly Booking"
                      control={
                        <Switch
                          checked={newInstant === 1}
                          onChange={(e) => {
                            const newInstantValue = e.target.checked ? 1 : 0;
                            setNewInstant(newInstantValue);
                            handleNewInstantChange(newInstantValue);
                          }}
                        />
                      }
                      style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "3%" }}
                    />
                  </FormGroup>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <FormGroup style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <FormControlLabel
                      label="Occupancy Status"
                      control={
                        <Switch
                          checked={newOccupancyStatus === 1}
                          onChange={(e) => {
                            const newOccupancyValue = e.target.checked ? 1 : 0;
                            setNewOccupancy(newOccupancyValue);
                            handleNewOccupancyChange(newOccupancyValue);
                          }}
                        />
                      }
                      style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "3%" }}
                    />
                  </FormGroup>
                </Grid>

              </Grid>

              <Grid container style={{ marginTop: '2%' }}>
                <div style={{
                  border: '1px solid #ddd',
                  padding: '10px',
                  textAlign: 'center',
                  width: '100%',
                  marginTop: '5px',
                  display: 'grid',
                  gap: '5px',
                  gridTemplateColumns: 'repeat(4, 1fr)', // Four equal-width columns
                }}>
                  {imagePreviews.map((preview, index) => (
                    <div key={index} style={{
                      position: 'relative',
                      width: '100%',
                      boxSizing: 'border-box',
                      overflow: 'hidden',
                    }}>
                      <img
                        src={preview}
                        alt={`Preview ${index}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      {/* <IconButton
                        style={{ position: 'absolute', top: '5px', right: '5px', color: 'black' }}
                        onClick={() => handleDeleteImage(index)}
                      > */}
                        {/* <CloseIcon />
                      </IconButton> */}
                    </div>
                  ))}

                  <label htmlFor="file-upload" style={{ cursor: 'pointer', width: '100%', height: "100%", boxSizing: 'border-box', border: '2px dashed #aaa', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '100%', boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <AddIcon fontSize="large" style={{ color: '#aaa' }} />
                    </div>
                    <input id="file-upload" type="file" hidden onChange={handleFileChange} accept=".pdf, .jpg, .jpeg, .png" multiple />
                  </label>
                </div>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop: '1rem', gap: '10px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="label"
                      style={{ marginLeft: '10px' }}
                    >
                      Upload images
                      <input
                        id="file-upload1"
                        type="file"
                        hidden
                        onChange={handleFileChange}
                        accept=".pdf, .jpg, .jpeg, .png"
                        multiple
                      />
                    </Button>
                    <Button
                      variant="text"
                      onClick={clearForm}
                      style={{ marginLeft: '10px', color: 'grey' }}
                    >
                      Clear
                    </Button>
                  </div>
                </Grid>
              </Grid>


              <div className="divider-amenities"></div>


              <h2 className="ameneties">Amenities</h2>  
              <Grid container style={{ paddingLeft: "5%" }}>
                <FormGroup sx={{ display: 'flex' }}>
                  {amenities.length > 0 &&
                    amenities.map((category) => (
                      <React.Fragment key={category.id}>
                        <Typography variant="h6">{category.name}</Typography>
                        <FormGroup sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          {category.amenities.map((amenity) => {
                            // const isAmenitySelected = selectedAmenities.some(selectedAmenity => selectedAmenity.id === amenity.id);

                            return (
                              <FormControlLabel
                                key={amenity.id}
                                control={
                                  <Checkbox
                                    onChange={getAmenityChangeHandler(amenity)}
                                    checked={selectedAmenities.some(selectedAmenity => selectedAmenity.id === amenity.id)}
                                  />
                                }
                                label={
                                  <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                      <img
                                        src={`${IMAGES_URL}${amenity.icon}`}
                                        alt={amenity.name}
                                        style={{ width: '24px', height: '24px' }}
                                      />
                                    </ListItemIcon>
                                    <Typography>{amenity.name}</Typography>
                                  </ListItem>
                                }
                              />
                            );
                          })}
                        </FormGroup>
                      </React.Fragment>
                    ))}

                </FormGroup>
              </Grid>


              <div className="divider-amenities"></div>


              <h2>Additional Information</h2>
              <div className="add-information">
                <Grid container style={{ marginTop: "5%" }}>
                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Number of Bedrooms</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        placeholder={String(propertyData.num_bedrooms)}
                        value={!isNaN(parseFloat(newNumBedroom)) ? parseFloat(newNumBedroom) : undefined}
                        onChange={(e) => {
                          const newNumBedroomValue = e.target.value;
                          const parsedValue = parseFloat(newNumBedroomValue);
                          const clampedValue = isNaN(parsedValue) ? '' : Math.min(99, Math.max(0, parsedValue));
                          setNewNumBedroom(clampedValue);
                          handleNewNumBedroomChange(newNumBedroomValue);
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Number of Beds</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        placeholder={String(propertyData.num_beds)}
                        value={!isNaN(parseFloat(newNumBeds)) ? parseFloat(newNumBeds) : undefined}
                        onChange={(e) => {
                          const newNumBedsValue = e.target.value;
                          const parsedValue = parseFloat(newNumBedsValue);
                          const clampedValue = isNaN(parsedValue) ? '' : Math.min(99, Math.max(0, parsedValue));
                          setNewNumBeds(clampedValue);
                          handleNewNumBedsChange(newNumBedsValue);
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Private Bathrooms</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        placeholder={String(propertyData.private_bathroom)}
                        value={!isNaN(parseFloat(newNumPrivateBathrooms)) ? parseFloat(newNumPrivateBathrooms) : undefined}
                        onChange={(e) => {
                          const newNumPrivateBathroomsValue = e.target.value;
                          const parsedValue = parseFloat(newNumPrivateBathroomsValue);
                          const clampedValue = isNaN(parsedValue) ? '' : Math.min(99, Math.max(0, parsedValue));
                          setNewNumPrivateBathrooms(clampedValue);
                          handleNewNumPrivateBathroomsChange(newNumPrivateBathroomsValue);
                        }}
                      />
                    </div>
                  </Grid>

                  {/* <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Number of Guests</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        placeholder={String(propertyData.num_guests)}
                        value={!isNaN(parseFloat(newNumGuests)) ? parseFloat(newNumGuests) : undefined}
                        onChange={(e) => {
                          const newNumGuestsValue = e.target.value;
                          const parsedValue = parseFloat(newNumGuestsValue);
                          const clampedValue = isNaN(parsedValue) ? '' : Math.min(99, Math.max(0, parsedValue));
                          setNewNumGuests(clampedValue);
                          handleNewNumGuestsChange(newNumGuestsValue);
                        }}
                      />
                    </div>
                  </Grid> */}

                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Dedicated Bathrooms</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        placeholder={String(propertyData.dedicated_bathroom)}
                        value={!isNaN(parseFloat(newNumDedicatedBathrooms)) ? parseFloat(newNumDedicatedBathrooms) : undefined}
                        onChange={(e) => {
                          const newNumDedicatedBathroomsValue = e.target.value;
                          const parsedValue = parseFloat(newNumDedicatedBathroomsValue);
                          const clampedValue = isNaN(parsedValue) ? '' : Math.min(99, Math.max(0, parsedValue));
                          setNewNumDedicatedBathrooms(clampedValue);
                          handleNewNumDedicatedBathroomsChange(newNumDedicatedBathroomsValue);
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Shared Bathrooms</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        placeholder={String(propertyData.shared_bathroom)}
                        value={!isNaN(parseFloat(newNumSharedBathrooms)) ? parseFloat(newNumSharedBathrooms) : undefined}
                        onChange={(e) => {
                          const newNumSharedBathroomsValue = e.target.value;
                          const parsedValue = parseFloat(newNumSharedBathroomsValue);
                          const clampedValue = isNaN(parsedValue) ? '' : Math.min(99, Math.max(0, parsedValue));
                          setNewNumSharedBathrooms(clampedValue);
                          handleNewNumSharedBathroomsChange(newNumSharedBathroomsValue);
                        }}
                      />
                    </div>
                  </Grid>

                  
                </Grid>
              </div>


              <div className="divider-amenities"></div>


              <h2>Add Location</h2>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Street"
                    variant="outlined"
                    style={{ width: "100%" }}
                    placeholder={propertyData.street}
                    value={newStreet !== '' ? newStreet : propertyData.street}
                    onChange={(e) => {
                      const newStreetValue = e.target.value.slice(0, 50);
                      propertyData.street = e.target.value.slice(0, 50);
                      setNewStreet(newStreetValue);
                      handleNewStreetChange(newStreetValue);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    id="outlined-basic1"
                    label="City"
                    variant="outlined"
                    style={{ width: "100%" }}
                    placeholder={propertyData.city}
                    value={newCity !== '' ? newCity : propertyData.city}
                    onChange={(e) => {
                      const newCityValue = e.target.value.slice(0, 50);
                      propertyData.city = e.target.value.slice(0, 50);
                      setNewCity(newCityValue);
                      handleNewCityChange(newCityValue);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    id="outlined-basic2"
                    label="State"
                    variant="outlined"
                    style={{ width: "100%" }}
                    placeholder={propertyData.state}
                    value={newState !== '' ? newState : propertyData.state}
                    onChange={(e) => {
                      const newStateValue = e.target.value.slice(0, 50);
                      propertyData.state = e.target.value.slice(0, 50);
                      setNewState(newStateValue);
                      handleNewStateChange(newStateValue);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    id="outlined-basic3"
                    label="Country"
                    variant="outlined"
                    style={{ width: "100%" }}
                    placeholder={propertyData.country}
                    value={newCountry !== '' ? newCountry : propertyData.country}
                    onChange={(e) => {
                      const newCountryValue = e.target.value.toString().slice(0, 50);
                      propertyData.country = e.target.value.toString().slice(0, 50);
                      setNewCountry(newCountryValue);
                      handleNewCountryChange(newCountryValue);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    id="outlined-basic4"
                    label="Zip Code"
                    variant="outlined"
                    style={{ width: "100%" }}
                    //placeholder={propertyData.zip_code}
            
                    value={newZipCode !== '' ? newZipCode : propertyData.zip_code}
                    onChange={(e) => {
                      const newZipCodeValue = e.target.value.slice(0, 30);
                      propertyData.zip_code = e.target.value.slice(0, 30);
                      setNewZipCode(newZipCodeValue);
                      handleNewZipCodeChange(newZipCodeValue);
                      
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <EditMapComponent apiKey={googleMapsApiKey} latt={newLatitude !== '' ? newLatitude : propertyData.latitude} longg={newLongitude !== '' ? newLongitude : propertyData.longitude} onLocationChange={handleLocationChangee} />
              {/* <EditMapComponent apiKey={googleMapsApiKey} latt="0" longg="0" onLocationChange={handleLocationChangee} /> */}
            </DialogContent>

          </div>
        </div>
      )}
    </div>
  );
};

export default EditProperty;
