import React from "react";
import StarIcon from "@mui/icons-material/Star";


const RatingComponent = ({ rating }) => {



  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<span style={{ fontSize: "0.7rem" }} key={i}>&#x2B50;</span>); 
     
     
    }
    return stars;
  };
  
  


  return (
    <div style={{ display: 'flex'  }}>
      {renderStars()}
    </div>


    

  );
};

export default RatingComponent;
