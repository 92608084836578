import React, { useState, useEffect } from "react";
import Config from "../../../../Services/Config";
import Navbar from "../../../../NavBar/NavBar";
import "./Information.css";
import { FormControl, TextField, Grid, Button } from "@mui/material";
import SnackbarComponent from "../../../../SnackBar/SnackBar";
import { IMAGES_URL } from "../../../../Services/Config";


function Information({ profileData }) {
  const [value, setValue] = React.useState("");


  const [name, setName] = useState(profileData.name);
  const [lastName, setLastName] = useState(profileData.last_name);
  const [email, setEmail] = useState(profileData.email);
  const [phoneNumber, setPhoneNumber] = useState(profileData.phone_number);
  const [passeportID, setPasseportID] = useState(profileData.passport_id);
  const [imgUrl, setImgUrl] = useState(
    profileData.profile_pic ? `${IMAGES_URL}${profileData.profile_pic}` : null
  );
  const [imageFile, setImageFile] = useState(null);
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // Handlers for each input field
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    let newPhoneNumber = event.target.value.slice(0,50);
    if(newPhoneNumber < 0){
      newPhoneNumber = 0;
    }
    if(!isNaN(newPhoneNumber) || newPhoneNumber === ''){
      setPhoneNumber(newPhoneNumber);
    }
    
    // setPhoneNumber(event.target.value);
  };
  const handlePasseportID = (event) => {
    const newPasseportID =event.target.value.slice(0, 12);
     if (newPasseportID.length <= 12 && newPasseportID.length >=0) {
       setPasseportID(newPasseportID);
     }
   };

  // const handleLatitudeChange = (event) => {
  //   const newLatitude = event.target.value.slice(0, 50);
  //   if(!isNaN(newLatitude) || newLatitude === '' || newLatitude === '-'){
  //     setPropertyData((prevData) => ({ ...prevData, latitude: newLatitude }));
  //   }
    
  // };


  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImageFile = event.target.files[0];
      setImgUrl(URL.createObjectURL(newImageFile));
      setImageFile(newImageFile);
      setIsImageChanged(true);
      console.log("New Image file : ",newImageFile);
      console.log("Image url : ",imgUrl)
      
    }
  };

  
  async function handleUpdate() {
    console.log(name, lastName, email, phoneNumber, passeportID);

    try {

        if(name.trim() !== "" && lastName.trim() !== "" && email.trim() !== "" && phoneNumber.trim() !== "" && passeportID.trim() !== ""){

          const result = await Config.updateUserDetails(name, lastName, email, phoneNumber,passeportID);
          if (result.success) {
              handleUpdateImage();
              // setProfileData(result.data);
              console.log(result.data);
              setOpenSnackbar(true);
              setSnackbarMessage(result.message);
              setSnackbarSeverity("success");
            
          } else {
            setOpenSnackbar(true);
            setSnackbarMessage(result.message);
            setSnackbarSeverity("error");
            return;
          }



        } else {
          if(name === ""){
            setOpenSnackbar(true);
            setSnackbarMessage("First Name is required");
            setSnackbarSeverity("error");
            return;
          }

          if(lastName === ""){
            setOpenSnackbar(true);
            setSnackbarMessage("Last Name is required");
            setSnackbarSeverity("error");
            return;
          }

          if(email === ""){
            setOpenSnackbar(true);
            setSnackbarMessage("Email is required");
            setSnackbarSeverity("error");
            return;
          }

          if(phoneNumber === ""){
            setOpenSnackbar(true);
            setSnackbarMessage("Phone Number is required");
            setSnackbarSeverity("error");
            return;
          }

          if(passeportID === "" || !passeportID){
            setOpenSnackbar(true);
            setSnackbarMessage("Passeport ID is required");
            setSnackbarSeverity("error");
            return;
          }

          if(!isImageChanged){
            setOpenSnackbar(true);
            setSnackbarMessage("Image has not changed");
            setSnackbarSeverity("info");
            return;
          }

        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
  
      

      
  }

  const handleUpdateImage = async () => {
    if (!imageFile) {
      return;
    }
    const formData = new FormData();
    formData.append("file", imageFile); // 'image' is the key expected by the API
    try {
      const result = await Config.updateUserImage(formData);
      if (result.success) {
       // setProfileData(result.data);
        console.log("result",result);
      } else {
        console.error(result.data);

      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    return () => {
      if (imgUrl) {
        URL.revokeObjectURL(imgUrl);
      }
    };
  }, [imgUrl]);

  if (!profileData) {
    return <div>Loading...</div>; // Or any other loading state representation
  }

  return (
    <div>
      {/* Wrapper with margin */}
      <h2>Profile</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <form noValidate autoComplete="off">
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className="profile-input"
                label="First Name"
                required
                type="text"
                variant="outlined"
                value={name}
                placeholder="Max length: 20 char"
                maxLength={20}
                onChange={handleNameChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className="profile-input"
                label="Last Name"
                type="text"
                required
                variant="outlined"
                value={lastName}
                placeholder="Max length: 20 char"
                maxLength={20}
                onChange={handleLastNameChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className="profile-input"
                label="Email"
                type="email"
                required
                placeholder="Max length : 30 char"
                maxLength={30}
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className="profile-input"
                label="Phone Number"
                required
                type="tel"
                variant="outlined"
                value={phoneNumber}
                maxLength={20}
                placeholder="Max length : 20 number"
                onChange={handlePhoneNumberChange}
                InputProps={{
                  inputMode : 'numeric',
                  pattern: '[0-9]*',
                }}

                // label="Latitude"
                // placeholder="Latitude"
                // type="text"
                // value={propertyData.latitude}
                // onChange={handleLatitudeChange}
                // style={{ width: '100%' }}
                // InputProps={{
                //   inputMode: 'numeric',
                //   pattern: '[0-9]*',
                // }}

              />
              
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className="profile-input"
                label=" ID"
                required
                type="text"
                variant="outlined"
                value={passeportID}
                maxLength={12}
                placeholder="Max length : 20 number"
                onChange={handlePasseportID}
                InputProps={{
                  inputMode: 'text',
                  pattern: '^[a-zA-Z0-9]{8,12}$',
                }}

                />
                </Grid>
          </form>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <img
            className="profile-img"
            src={imgUrl || `${IMAGES_URL}${profileData.profile_pic}`}
            alt="Profile"
            style={{ width: "220px", height: "220px", marginBottom: "10px" }} // Adjust styling as needed
          />

          <Button
            variant="outlined"
            color="primary"
            onClick={() => document.getElementById("image-upload").click()}
            style={{ width: "220px" }}
          >
            Edit Image
          </Button>

          <input
            type="file"
            id="image-upload"
            style={{ display: "none" }}
            onChange={handleImageChange}
            accept="image/*"
          />
        </Grid>
      </Grid>

      <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
        <Grid item xs={3} md={2} lg={1} style={{ margin: "20px" }}>
          <Button
            variant="contained"
            className="profile-submit"
            onClick={handleUpdate}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <SnackbarComponent
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
}

export default Information;
