import React, { useState } from 'react';
import AuthService from '../Services/AuthService';
import { API_URL } from '../Services/Config';
import axios from "axios";
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import './ResetPasswordForm.css';
import SnackbarComponent from '../SnackBar/SnackBar';

const ResetPasswordForm = ({ onSubmit }) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let loginResponseMessage, severity;
    if(password!==passwordConfirmation){
      loginResponseMessage = "Passwords do not match";
      severity = "error";
      setOpenSnackbar(true);
      setSnackbarMessage(loginResponseMessage);
      setSnackbarSeverity(severity);
      return;
    }
    if(password.length<6){
      loginResponseMessage = "The password must be at least 6 characters.";
      severity = "error";
      setOpenSnackbar(true);
      setSnackbarMessage(loginResponseMessage);
      setSnackbarSeverity(severity);
      return;
    }
    try {
      const body = {
        token: new URLSearchParams(window.location.search).get('token'),
        password: password,
        password_confirmation: passwordConfirmation
      };
    AuthService.resetNewPasswords(body).then(
      (response) => {
        

        if (response.success) {
          //loginResponseMessage = "Password Reset Was Successful";
          loginResponseMessage = response.message;
          severity = "success";
          setTimeout(() => {
            window.location.href = '/';
        }, 500);
        } else {
          loginResponseMessage = response.message;
          severity = "error";
        }

        console.log(loginResponseMessage);
        setOpenSnackbar(true);
        setSnackbarMessage(loginResponseMessage);
        setSnackbarSeverity(severity);
      },
      (error) => {
        console.log("Password Reset failed", error);
      }
    );
  } catch (error) {
    console.error(error);
  }
}
if(new URLSearchParams(window.location.search).get('token')==null||new URLSearchParams(window.location.search).get('token')==""){
  window.location.href = '/';
}else{
 return (
    <div className="container-reset-password-form">
      <form onSubmit={handleSubmit}>
        <div className="form-container">
          <SnackbarComponent
                open={openSnackbar}
                setOpen={setOpenSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
          <Box 
            height={250}
            width={400}
            my={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={4}
            p={4}
            sx={{ border: '2px solid grey' }}
            borderRadius={3}
          >
            <label style={{fontSize:"x-large"}}>Reset Password</label>
            <TextField 
              label="New Password" 
              variant="outlined" 
              required onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              placeholder="Max length : 20 char"
              maxLength={20}
            />
            <TextField 
              label="Confirm New Password" 
              variant="outlined" 
              required onChange={(e) => setPasswordConfirmation(e.target.value)}
              value={passwordConfirmation}
              type="password"
              placeholder="Max length : 20 char"
              maxLength={20}
            />
            <Button variant="outlined" type="submit" color="error" size="large">Reset Password</Button>
          </Box>
          </div>
      </form>
    </div>
  );
 }
};

export default ResetPasswordForm;
