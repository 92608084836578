import React, { useState , useEffect  } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const EditMapComponent = ({ apiKey, onLocationChange, latt, longg}) => {
  const [mapCenter, setMapCenter] = useState({ lat: latt, lng: longg });
  const [markerPosition, setMarkerPosition] = useState(null);
  useEffect(() => {
    // Update marker and map center when latt or longg changes
    setMarkerPosition({ lat: latt, lng: longg });
    setMapCenter({ lat: latt, lng: longg });
    console.log("laaatt" ,latt);
  }, [latt, longg]);

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setMarkerPosition({ lat, lng });

    onLocationChange({ latitude: lat, longitude: lng });
  };


  return (
    <GoogleMap
      mapContainerStyle={{ height: '400px', width: '100%' }}
      zoom={14}
      center={mapCenter}
      onClick={handleMapClick}
    >
      {markerPosition && <Marker position={markerPosition} />}
    </GoogleMap>
  );
};

export default EditMapComponent;
