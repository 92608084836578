import axios from "axios";
import { API_URL } from "./Config";

const TOKEN_KEY = "token";

class AuthService {
  tokens = this.getToken();
  login(body) {
    return axios.post(`${API_URL}/login`, body).then((response) => {
      if (response.data.success) {
        console.log(response.data.message);
        localStorage.setItem(TOKEN_KEY, response.data.message);
      }
      return response.data;
    });
  }

  resetPassword(body) {
    return axios.post(`${API_URL}/user/resetPassword`, body).then((response) => {
      if (response.data.success) {
        console.log(response.data.message);
      }
      return response.data;
    });
  }

  async resetNewPasswords(body) {
    const response = await axios.post(`${API_URL}/reset-password`, body);
    if (response.data.success) {
      console.log(response.data.message);
    }
    return response.data;
  }

  signup(registrationData) {
    return axios.post(`${API_URL}/register`, registrationData).then((response) => {
      if (response.data.success) {
        // localStorage.setItem(TOKEN_KEY, response.data.message);
      }
      return response.data;
    // console.log(response.data);
    });
    
  }

  logout() {
    const token = this.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    localStorage.removeItem(TOKEN_KEY);
    return axios.post(`${API_URL}/logout`, {}, config).then((response) => {
      if (response.data.success) {
        console.log(true);
      }
      return response.data;
    });
  }

  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }
}

export default new AuthService();
