import React, { useState , useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';

const ViewMapComponent = ({ apiKey, onLocationChange, latt, longg}) => {
  const [mapCenter, setMapCenter] = useState({ lat: latt, lng: longg });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  useEffect(() => {
    setMarkerPosition({ lat: latt, lng: longg });
    setMapCenter({ lat: latt, lng: longg });

    const timer = setTimeout(() => {
      setInfoWindowOpen(true);
    }, 2000);

    // Cleanup function
    return () => clearTimeout(timer);
  }, [latt, longg]);

  const handleMarkerClick = () => {
    setInfoWindowOpen(true);
  };

  const handleInfoWindowClose = () => {
    setInfoWindowOpen(false);
  };

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setInfoWindowOpen(false);
  };

  const handleInfoWindowConfirm = () => {
    // Handle opening the selected point in Google Maps for directions
    const { lat, lng } = markerPosition;
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    window.open(url, '_blank');
  };

  return (
    <GoogleMap
      mapContainerStyle={{ height: '400px', width: '100%' }}
      zoom={14}
      onClick={handleMapClick}
      center={mapCenter}
    >
      {markerPosition && (
        <Marker
          position={markerPosition}
          onClick={handleMarkerClick}
        />
      )}
      
      {infoWindowOpen && markerPosition && (
        <InfoWindow
          position={markerPosition}
          onCloseClick={handleInfoWindowClose}
        >
          <div>
            <p>Would you like to open this point in Google Maps for directions?</p>
            <button onClick={handleInfoWindowConfirm}>Open in Google Maps</button>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default ViewMapComponent;
