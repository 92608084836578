import React, { useState , useEffect  } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const MapComponent = ({ apiKey, onLocationChange }) => {
  //const [mapCenter, setMapCenter] = useState({ lat: 33.8938, lng: 35.5018 });
  const [mapCenter, setMapCenter] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);

  useEffect(() => {
    // Get user's current location if mapCenter is not set
    if (!mapCenter) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });
          onLocationChange({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
  }, [mapCenter, onLocationChange]);

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setMarkerPosition({ lat, lng });

    onLocationChange({ latitude: lat, longitude: lng });
  };


  return (
    <GoogleMap
      mapContainerStyle={{ height: '400px', width: '100%' }}
      zoom={14}
      center={mapCenter}
      onClick={handleMapClick}
    >
      {markerPosition && <Marker position={markerPosition} />}
    </GoogleMap>
  );
};

export default MapComponent;
