import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchBar from "./SearchBar/SearchBar";
import Card from "./Card/Card";
import Footer from "./Footer/Footer";
import { API_URL } from "./Services/Config";
import NavbarWithObserver from "./NavBar/NavBar";
import Config from "./Services/Config";

function Home() {
  const [cardsData, setCardsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [long, setLongitude] = useState(0);
  const [lat, setLatitude] = useState(0);

  const handleSetOpenDialog = () => {
    setOpenDialog(true);
  }
  const handleSetCloseDialog = () => {
    setOpenDialog(false);
  }


  //to get the current location via public ip
  useEffect(() => {
    const fetchHomeCardDetails = async () => {
      const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const { latitude, longitude } = [data.latitude,data.longitude];
        setLongitude(data.longitude);
        setLatitude(data.latitude);

    }
    setLoading(true);
    fetchHomeCardDetails()
  }, []);

  useEffect(() => {
    const getAllProperties = async () => {
      try {
        console.log("longitude", long);
        console.log("latitude", lat);
        const data = await Config.getHomeCardDetails(long, lat);
        console.log("data",data);
        setCardsData(data);
        setFilteredCards(data);
        setLoading(false);
      } catch (exception) {
        console.error(exception);
        setLoading(false);
      }
    };

    if (long !== 0 && lat !== 0) {
      getAllProperties();
    }
  }, [long,lat]);


  
  // useEffect(() => {
  //   const fetchHomeCardDetails = async () => {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setLongitude(longitude);
  //         setLatitude(latitude);
  //         try {
  //           console.log("long down",longitude,"lat down ",latitude);
  //           const getAllProperties = async () => {
  //             try {
  //               const data = await Config.getHomeCardDetails(longitude,latitude);
  //               console.log("data on loading page ",data)
  //               setCardsData(data);
  //               setFilteredCards(data);
  //               setLoading(false);
  //             } catch (exception){
  //               console.error(exception);
  //               setLoading(false);
  //             }
  //           };
  //           getAllProperties();
            
  //         } catch (error) {
  //           setError(error);
  //           setLoading(false);
  //         }
  //       },
  //       (error) => {
  //         console.error(`Error getting geolocation: ${error.message}`);
  //       }
  //     );

      
  //   };

  //   fetchHomeCardDetails();
  // }, []);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       const { latitude, longitude } = position.coords;
  //       setLongitude(longitude);
  //       setLatitude(latitude);
  //       // console.log(`Latitude here : ${latitude}, Longitude here : ${longitude}`);
  //     },
  //     (error) => {
  //       console.error(`Error getting geolocation: ${error.message}`);
  //     }
  //   );
  // },[]);


  // console.log("lat",lat);
  // console.log("long",long);


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);


  const handleSearch = async ({ location, checkIn, checkOut, guests, minPrice, maxPrice, selectedCategory, maxRange, selectedPropertyType, selectedBedroom, selectedBeds, selectedBathroom, long, lat }) => {

    try {
      // console.log("testing");

      if(checkIn == null){
          
        checkIn = "";
        checkOut = "";
      }


        const response = await Config.searchFunctionality(
          location,
          checkIn,
          checkOut,
          guests,
          minPrice,
          maxPrice,
          selectedCategory,
          maxRange,
          selectedPropertyType,
          selectedBedroom,
          selectedBeds,
          selectedBathroom,
          long,
          lat
        );

        // const response = await Config.getHomeCardDetails(long,lat);

        console.log("response search button : ",response);
        setCardsData(response);
        setFilteredCards(response);
        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };


  const applyFilters = () => {

    const filteredData = cardsData.filter((data) => {
      const lowercasedDataLocation = data.location.toLowerCase().trim();
      const includesCheck = lowercasedDataLocation.includes(
        searchInput.toLowerCase()
      );
      const priceCheck =
        data.price >= parseInt(priceRange.min, 10) &&
        data.price <= parseInt(priceRange.max, 10);

      return includesCheck && priceCheck;
    });

    setFilteredCards(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [searchInput, cardsData]);

  return (
    <div className="App">

      <NavbarWithObserver openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} />

      <SearchBar onSearch={handleSearch} />

      {loading ? (

        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <div className="card-grid">
          {filteredCards.length > 0 ? (
            filteredCards.map((data) => (
              <div key={data.id} style={{ marginTop: "2%" }}>
                <Card
                  id={data.id}
                  name={data.name}
                  price={data.price}
                  location={data.location}
                  rating={data.rating}
                  available_at={data.available_at}
                  availability_ends_at={data.availability_ends_at}
                  distance={data.distance}
                  property_images={data.property_images}
                />
              </div>
            ))
          ) : (
            <div>No matching properties found.</div>
          )}
        </div>

      )}

      <Footer />
    </div>
  );
}

export default Home;
