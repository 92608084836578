/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import './Footer.css';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { IconButton } from '@mui/material';
import LoginPopup from '../LoginSignup/LoginPopup';
import SignupPopup from '../LoginSignup/SignupPopup';




const Footer = () => {

  const [loginOpen, setLoginOpen] = useState(false);
  const [backgroundAnimated, setBackgroundAnimated] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);

  const handleSignupClose = () => {
    setSignupOpen(false);
    setBackgroundAnimated(true);
  };

  const switchToLogin = () => {
    handleSignupClose();
    handleLoginOpen();
  };

  const handleLoginOpen = () => {
    setLoginOpen(true);
    setSignupOpen(false);
  };


  const switchToSignup = () => {
    handleLoginClose();
    handleSignupOpen();
  };

  const handleSignupOpen = () => {
    setLoginOpen(false);
    setSignupOpen(true);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
    setBackgroundAnimated(false);
    // console.log("close login");
  };

  return (
    <footer className="site-footer">
      <Grid container className="footer-content" width='100%' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {/* <Grid item lg={5} xs={12} className="footer-left" height={250} >
        
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%'}}>
            <div className="logo">
              <a href="/"><img src="/LOGO-hamroghar.png" alt="Your Logo" className="img-logo" /></a>
            </div>
            <div className="text" style={{ width: '1000px'}}>
              <h1 className="footer-h1" style={{fontSize: '24px', fontWeight: 'bold'}}><label>Dhaam Rental Private Limited</label></h1>
              <p className="textt"style={{ width: '90%', padding: '20px'}}>
                Unlock a World of Rental Possibilities with Dhaam – Your Trusted Partner for Hassle-Free Property Renting Platform in Nepal. नेपाल बनाउने पनि त नेपाली ले नै हो! 
              </p>
            </div>
          </div>
        </Grid> */}
        <Grid item className="footer-left" width='100%'>
          {/* <Grid container className='footer-container' >
            <Grid item className='footer-links'>
            <div className="logo">
              <a href="/"><img src="/LOGO-hamroghar.png" alt="Your Logo" className="img-logo" /></a>
            </div>
            </Grid>
            <Grid item className="footer-links" style={{display: 'flex', justifyContent: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            {/* <h1 className="footer-h1" style={{fontSize: '24px', fontWeight: 'bold'}}><label>Dhaam Rental Private Limited</label></h1>
              <p className="textt" style={{  width: '100%'}}>
                Unlock a World of Rental Possibilities with Dhaam – Your Trusted Partner for Hassle-Free Property Renting Platform in Nepal. नेपाल बनाउने पनि त नेपाली ले नै हो! 
              </p> 
              <h1 className="footer-h1"><label>Dhaam Rental Private Limited</label></h1>
              <ul>
                <li>Unlock a World of Rental Possibilities with Dhaam – Your Trusted Partner for Hassle-Free Property Renting Platform in Nepal. नेपाल बनाउने पनि त नेपाली ले नै हो! </li>
              </ul>

            </Grid>
          </Grid> */}

          <Grid container className='footer-container'>
            <div item bgcolor='red'>
              <div className="logo">
                <a href="/"><img src="/LOGO-hamroghar.png" alt="Your Logo" className="img-logo" /></a>
              </div>
            </div>
            <div item className="footer-links">
              <h1 className="footer-h1"><label>Dhaam Rental Private Limited</label></h1>
              <ul>
                <li>Unlock a World of Rental Possibilities with Dhaam </li>
                <li>– Your Trusted Partner for Hassle-Free</li>
                <li> Property Renting Platform in Nepal.</li>
                <li>नेपाल बनाउने पनि त नेपाली ले नै हो! </li>
              </ul>
            </div>
            <div item className="footer-links">
              <h1 className="footer-h1"><label>Company Info</label></h1>
              <ul>
                <li>Address: 10 Baneshwor, Kathmandu</li>
                <li>Email: info@dhaamrental.com</li>
                <li>Phone: +9779866752403</li>
                <li>Registration Number: 324775/080/081</li>
              </ul>
            </div>

            <div className="footer-links">
              <h1 className="footer-h1"><label>Help</label></h1>
              <ul>
                <li><a href="#">Support</a></li>
                <li><a onClick={handleLoginOpen} style={{cursor:'pointer'}}>Sign in</a></li>
                <li><a onClick={handleSignupOpen} style={{cursor:'pointer'}}>Sign up</a></li>
              </ul>
            </div>

            {/* <Grid item lg={4} md={4} sm={4} xs={4} className="footer-links">
              <h1 className="footer-h1"><label>Properties</label></h1>
              <ul>
                <li><a href="#">Listings</a></li>
                <li><a href="#">Featured properties</a></li>
                <li><a href="#">Rent</a></li>
              </ul>
            </Grid> */}
          </Grid>
        </Grid>

        {/* <Grid item lg={5} xs={12} height={250} className="footer-middle">
          
        </Grid> */}
      </Grid>


      <Grid container className="footer-right">
        <Grid item lg={12} md={12} sm={12} xs={12} className="footer-social">
          <p>Follow Us:</p>
          <div className="footer-logos">
            <a href="https://www.facebook.com/">
              <IconButton
                style={{ color: 'black', transition: 'color 0.25s' }}
                onMouseOver={(e) => e.currentTarget.style.color = 'blue'}
                onMouseOut={(e) => e.currentTarget.style.color = 'black'}
              >
                <FacebookIcon fontSize='large' />
              </IconButton>
            </a>
            <a href="https://www.instagram.com/">
              <IconButton
                style={{ color: 'black', transition: 'color 0.25s' }}
                onMouseOver={(e) => e.currentTarget.style.color = 'blue'}
                onMouseOut={(e) => e.currentTarget.style.color = 'black'}
              >
                <InstagramIcon fontSize='large' />
              </IconButton>
            </a>
            <a href="https://twitter.com/?lang=en">
              <IconButton
                style={{ color: 'black', transition: 'color 0.25s' }}
                onMouseOver={(e) => e.currentTarget.style.color = 'blue'}
                onMouseOut={(e) => e.currentTarget.style.color = 'black'}
              >
                <TwitterIcon fontSize='large' />
              </IconButton>
            </a>

          </div>
        </Grid>
      </Grid>

        Terms and Conditions: ​Dhaam Rental is not responsible for any unauthorized loss of money. Please do not give your money to anyone outside our platform. We will never call you asking to transfer the money.

      <LoginPopup isOpen={loginOpen} onClose={handleLoginClose} onSwitchToSignup={switchToSignup} />
      

      <SignupPopup isOpen={signupOpen} onClose={handleSignupClose} onSwitchToLogin={switchToLogin} />

      <div className="footer-bottom">
        <p className="bot-text">&copy; 2024 Your Real Estate. All rights reserved. Powered by <a href="https://www.eddysgroup.com/" className="powered-by">Eddy's Group</a>.</p>
      </div>
    </footer>
  );
};


    // {
    //   "src": "favicon.ico",
    //   "sizes": "64x64 32x32 24x24 16x16",
    //   "type": "image/x-icon"
    // },

export default Footer;