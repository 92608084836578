import "./Card.css";
import React from "react";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import { IMAGES_URL } from "../Services/Config";
import  RatingComponent  from "../Components/Star/RatingComponent";

const myCard = ({
  name,
  price,
  location,
  available_at,
  availability_ends_at,
  rating,
  distance,
  property_images,
  id,
}) => {
  const imagesWithBaseUrl = property_images.map((image) => {
    return `${IMAGES_URL}${image.image_url}`;
  });

  const imageUrl = imagesWithBaseUrl.length > 0 ? imagesWithBaseUrl[0] : "";

  const redirectToUrl = () => {
    const url = `/propertydetails?id=${id}`;
    // window.open(url, "_blank");
    window.location.href = url;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  function formatRange(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    ) {
      return `${formatDate(start)} - ${endDate.getDate()}`;
    } else {
      return `${formatDate(start)} - ${formatDate(end)}`;
    }
  }

  return (
    <div>
      <Card
        className="cards"
        sx={{
          boxShadow: "none",
          "&:hover": {
            boxShadow: "rgb(116, 116, 116) 0px 2px 8px 0px;",
          },
        }}
        onClick={redirectToUrl}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="240"
            image={imageUrl}
            alt={`Property Image 1`}
          />
          <div className="what">
            <CardContent style={{ backgroundColor: "rgb(230, 230, 230)", height: '100%'}} className="whatever">
              <Typography variant="h6" component="div" style={{ fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{marginBottom: '0.5rem'}}>{location}</div><br />
                  <div style={{ display: "flex" }}>
                   
                    <RatingComponent rating={rating} />

                    {/* <StarIcon
                      style={{ fontSize: "1rem", verticalAlign: "middle" }}
                    /> */}
                    {/* <span
                      style={{
                        fontSize: "1rem",
                        verticalAlign: "middle",
                        marginLeft: "4px",
                      }}
                    >
                      {rating}
                    </span> */}
                  </div>
                </div>
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif" }}>
                <span>{formatRange(available_at, availability_ends_at)}</span><br />
                <span style={{ marginTop: "1rem"}}>{distance.toFixed(1)} KM(s) away</span><br />
                <div style={{marginTop: '0.5rem',display:'block'}}>
                <span style={{ display:'block', fontWeight: "bold" , wordWrap: 'break-word', whiteSpace: 'normal'}}>Price: <label  >${price} / Month</label> </span>

                {/* <span style={{display:'block',fontWeight:'bold',wordWrap: 'break-word', whiteSpace: 'normal'}}>Price:hadkjwajwlkdawdo;jlkwad;ajlkwnd,aw;dljkn,awdkn,m / Night</span> */}
                </div>
              </Typography>
            </CardContent>
          </div>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default myCard;
